<template>
    <div class='safe'>
        <Form :formItems="formItems" :button="button" @confirm="confirm"/>
    </div>
</template>
<script>
import { reactive, toRefs } from 'vue'
import Form from '../../components/form.vue'
import { getQueryStr, goBack, inputIsAllFill, toast, getUserInfo } from '../../utils/util'
import { resetUserInfo } from '../../utils/api'
export default {
    name: '',
    components: {Form},
    setup() {
         const state= reactive({
             formItems: [
                 {label: '真实姓名', type: 'read', value: ''},
                 {label: '手机号码', type: 'read', value: ''},
                 {label: '身份证号', type: 'read', value: ''},
                 {label: '登陆卡号', type: 'read', value: ''},
             ],
             button: {
                 label: '确定',
                 eventName: 'confirm'
             }, 
             idCard: ''
        })
        const name = getQueryStr('name') || ''
        const IdCard = getQueryStr('IdCard') || ''
        async function initData() {
            const items = state.formItems
            items[0].value = name == 'null' ? '' : name
            if (items[0].value == 'null' || items[0].value == '') {
                items[0].type = 'input'
            } else {
                state.button.label = ''
            }
            items[2].value = IdCard == 'null' ? '' : IdCard
            items[2].value = items[2].value.replace(/^(.{1})(?:\d+)(.{1})$/,"$1**********$2")
            const info = await getUserInfo()
            state.idCard = info.cardNumber
            items[1].value = info.mobile
            items[3].value = info.userNo
            // if (name !== 'null' || name !== '') {
            //     items.forEach(ele => {
            //         ele.type="read"
            //     })
            //     state.button.label = ''
            // }
        }
        async function confirm() {
            try {
                if (inputIsAllFill(state.formItems)) {
                    const items = state.formItems
                    const params = {
                        realName: items[0].value,
                        // cardNumber: state.idCard
                    }
                    const result = await resetUserInfo(params)
                    if (result.code == 0){
                        await getUserInfo(true)
                        toast('提交成功')
                        goBack()
                    }
                } else{
                    toast('前先填写完整信息再提交')
                }
            } catch(e) {
                console.error(e)
            }
        }
        initData()
        return {
            ...toRefs(state),
            confirm
        }
    },
}
</script>
<style lang='scss' scoped>
</style>